import React, { useCallback, useEffect } from 'react';
import { useDispatch } from "react-redux";

import { setTokens } from "./store/slices/user";

import Home from './pages/Home';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("access-token");
  const refreshToken = queryParams.get("refresh-token");
  const platform = queryParams.get("platform");

  const dispatch = useDispatch();

  const initFunction = useCallback(() => {
    dispatch(setTokens({ accessToken: accessToken, refreshToken: refreshToken, platform: platform}));
  }, [accessToken, dispatch, platform, refreshToken]);

  useEffect(() => {
    initFunction()
  }, [initFunction]);

  return (
    <div className={`App platform-${platform}`}>
      <Home />
    </div>
  )
}

export default App;
