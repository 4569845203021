import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  accessToken: null,
  refreshToken: null,
  platform: 'web',
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      if (action.payload.platform) {
        state.platform = action.payload.platform;
      }
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setTokens,
  setAccessToken,
} = userSlice.actions;

export default userSlice.reducer;