const CONSTANTS = {};

CONSTANTS.paymentStreamTypes = Object.freeze({
  GUAR_ANN: 'Guaranteed Period',
  LC_ANN: 'Life Contingent',
  GUAR_LC_ANN: 'Guarenteed Period - Continued for Life',
});

CONSTANTS.paymentFrequencies = Object.freeze({
  '1week': 'Weekly',
  '2weeks': 'Bi-Weekly',
  '1month': 'Monthly',
  '2months': 'Bi-Monthly',
  '3months': 'Quarterly',
  '1year': 'Yearly',
  '2years': 'Bi-Yearly',
  LUMP_SUM: 'LumpSum',
  lump_sum: 'LumpSum',
  lumpsum: 'LumpSum',
  LumpSum: 'LumpSum',
});

CONSTANTS.infoPopupContents = Object.freeze({
  'policy-value': 'COMPD searches for the best capital providers for your policy. This present value of your policy is calculated based on the best interest available in the market.',
  'slider-info': 'You can use the sliders to choose how much you would like to select from each term individually',
  'stream-present-value': 'This is the total present value of this payment stream. It refers to how much you can request from it today',
  'enable-yearly-view-info': 'You are in \'All Years\' mode, you can select a portion of each payment at once. To modify individual year selection, close this window and untick the \'All Years\'',
  'reset-info': 'Your selections for this payment stream will be set to zero',
  'all-years-checked': 'Selecting All Years would make you loose your current settings in the payout simulator',
  'all-years-unchecked': 'Deselecting \'All Years\' would take you back to zero settings for the payout simulator',
});

export default CONSTANTS;