import moment from "moment";
import CONSTANTS from "./constants";

const helpers = {};

const paymentStreamBreakdown = (paymentStream = {}) => {
  const breakdown = {};

  if (paymentStream && paymentStream.payments && paymentStream.payments.length > 0) {
    const streamsByYears = {};
    for (const s of paymentStream.payments) {
      const year = moment(s.date).format('YYYY');
      if (!streamsByYears[year]) {
        streamsByYears[year] = {
          selected: false,
          present_value: 0,
          payment_streams: {},
          payment_ids: [],
          selectedAmount: 0,
        };
      }

      if (['1week', '2weeks', '1month', '2months', '3months'].includes(paymentStream.intervalType)) {
        streamsByYears[year].payment_streams[moment(s.date).format('M')] = {
          ...s,
          selected: false,
          present_value: Number(s.present_value),
          payment_ids: [s.payment_id],
          selectedAmount: Number(s.selectedAmount),
        };
      } else {
        streamsByYears[year] = {
          ...s,
          selected: false,
          present_value: 0,
          payment_ids: [],
          selectedAmount: 0,
        };
      }

      streamsByYears[year].present_value += Number(s.present_value);
      streamsByYears[year].selectedAmount += Number(s.selectedAmount);
      streamsByYears[year].payment_ids.push(s.payment_id);
    }

    let years = Number(moment(paymentStream.payments[0].date).format('YYYY'));
    if ([CONSTANTS.paymentFrequencies.LUMP_SUM, CONSTANTS.paymentFrequencies.LumpSum, CONSTANTS.paymentFrequencies.lump_sum, CONSTANTS.paymentFrequencies.lumpsum].includes(paymentStream.intervalType)) {
      const yearGroup = `${years}`;
      breakdown[yearGroup] = {
        selected: false,
        present_value: 0,
        payment_streams: {},
        payment_ids: [],
        selectedAmount: 0,
      };

      breakdown[yearGroup].payment_streams[years] = streamsByYears[years];
      breakdown[yearGroup].present_value += Number(streamsByYears[years].present_value);
      breakdown[yearGroup].selectedAmount += Number(streamsByYears[years].selectedAmount);
      breakdown[yearGroup].payment_ids = [...breakdown[yearGroup].payment_ids, ...streamsByYears[years].payment_ids];
    } else {
      for (let i = 0; i < Object.keys(streamsByYears).length; i = i + 5) {
        const yearGroup = `${years}-${years + 4}`;
        breakdown[yearGroup] = {
          selected: false,
          present_value: 0,
          payment_streams: {},
          payment_ids: [],
          selectedAmount: 0,
        };
        for (let j = 0; j <= 4; j++) {
          if (streamsByYears[years + j]) {
            breakdown[yearGroup].payment_streams[years + j] = streamsByYears[years + j];
            breakdown[yearGroup].present_value += Number(streamsByYears[years + j].present_value);
            breakdown[yearGroup].selectedAmount += Number(streamsByYears[years + j].selectedAmount);
            breakdown[yearGroup].payment_ids = [...breakdown[yearGroup].payment_ids, ...streamsByYears[years + j].payment_ids];
          }
        }
        years = years + 5;
      }
    }
  }

  return breakdown;
};

const policyPaymentStreams = (policy, selected = false) => {
  const paymentStreams = {};
  for (const ps of Object.keys(policy.payment_streams)) {
    const payments = policy.payment_streams[ps].policyType === 'GUAR_ANN' ? policy.payment_streams[ps].guaranteed_payments : policy.payment_streams[ps].life_contingent_payments;

    paymentStreams[ps] = {
      ...policy.payment_streams[ps],
      selected: selected,
      selectedAmount: selected ? Number(policy.payment_streams[ps].present_value) : 0,
      payments: payments.map(p => { return { ...p, selectedAmount: selected ? Number(p.present_value) : 0 } }),
    };
    
    paymentStreams[ps].breakdown = paymentStreamBreakdown(paymentStreams[ps]);
  }

  policy.payment_streams = { ...paymentStreams };

  return { ...policy };
}

const calculateRequestingAmount = (payout) => {
  let total = 0;
  let totalDeduction = 0;
  let adjusted = 0;
  let adjustedType = null;

  const policies = payout.policies;
  for (const pId of Object.keys(policies)) {
    for (const psId of Object.keys(policies[pId].payment_streams)) {
      if (policies[pId].payment_streams[psId].selected) {
        total += Number(policies[pId].payment_streams[psId].selectedAmount);
        for (const p of policies[pId].payment_streams[psId].payments) {
          if (Number(p.present_value) > 0 && Number(p.selectedAmount) > 0) {
            const percent = (Number(p.selectedAmount) / Number(p.present_value)) * 100;
            totalDeduction += (Number(p.deduction) * percent) / 100;
          }
        }
      }
    }
  }

  if (Number(total) > 0) {
    if (Number(totalDeduction) > Number(payout.max_deduction)) {
      adjusted = Number(payout.max_deduction) - Number(totalDeduction);
      adjustedType = 'boost';
    } else if (Number(totalDeduction) < Number(payout.min_deduction)) {
      adjusted = Number(payout.min_deduction) - Number(totalDeduction);
      adjustedType = 'small withdrawal adjustment';
    }
  }

  total = Number(total) - Number(adjusted);

  return { total, adjusted, adjustedType };
}

helpers.paymentStreamBreakdown = paymentStreamBreakdown;
helpers.policyPaymentStreams = policyPaymentStreams;
helpers.calculateRequestingAmount = calculateRequestingAmount;

export default helpers;