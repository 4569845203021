import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import moment from "moment";

import { SlArrowDown } from "react-icons/sl";
import { LiaInfoCircleSolid } from "react-icons/lia";

import CONSTANTS from "../../utils/constants";

import RangeSlider from "../RangeSlider";

import { changePaymentStreamSelectedAmount } from "../../store/slices/payout";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const PaymentStreamBreakdown = ({
  policyId = null,
  paymentStreamId = null,
  open = false,
  onClose,
  showInfoPopup,
  showResetPopup,
  resetSelection,
  showAllYearPopup,
  allYearSelection,
}) => {
  const payout = useSelector(state => state.payout);
  const dispatch = useDispatch();

  const [selectedGroupKey, setSelectedGroupKey] = useState(null);
  const [selectedYearKey, setSelectedYearKey] = useState(null);
  const [selectedMonthKey, setSelectedMonthKey] = useState(null);
  const [paymentStream, setPaymentStream] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [yearlyView, setYearlyView] = useState(false);
  const [monthlyView, setMonthlyView] = useState(false);
  const [allYearsSelected, setAllYearsSelected] = useState(false);

  const onSelectPSYearGroup = (key) => {
    if (allYearsSelected) return false;
    setMonthlyView(false);
    setSelectedMonthKey(null);
    setYearlyView(false);
    setSelectedYearKey(null);
    setSelectedGroupKey(key);
  }

  const onSelectPSYear = (key) => {
    if (allYearsSelected) return false;
    setMonthlyView(false);
    setSelectedMonthKey(null);
    setSelectedYearKey(key);
  }

  const onSelectPSMonth = (key) => {
    if (allYearsSelected) return false;
    setSelectedMonthKey(key);
  }

  const changePSSelectedAmount = (value) => {
    const params = {
      policyId,
      paymentStreamId,
      value
    };
    if (!allYearsSelected) {
      params.groupKey = selectedGroupKey;
      params.yearKey = yearlyView ? selectedYearKey : undefined;
      params.monthKey = monthlyView ? selectedMonthKey : undefined;
    }

    dispatch(changePaymentStreamSelectedAmount(params));
  }
  const handleOnChangeGroup = debounce((value) => changePSSelectedAmount(value), 300);
  const handleOnChangeYear = debounce((value) => changePSSelectedAmount(value), 300);
  const handleOnChangeMonth = debounce((value) => changePSSelectedAmount(value), 300);

  const groupKeyLabel = (key) => {
    const years = key.split('-');

    if ([CONSTANTS.paymentFrequencies.LUMP_SUM, CONSTANTS.paymentFrequencies.LumpSum, CONSTANTS.paymentFrequencies.lump_sum, CONSTANTS.paymentFrequencies.lumpsum].includes(paymentStream.intervalType)) {
      return `${years[0]}`;
    }
    return `${years[0]}-${moment(`${years[1]}-01-01`).format('YY')}`;
  }

  const enableYearlyView = (e) => {
    if (allYearsSelected) {
      showInfoPopup('enable-yearly-view-info');
      return false;
    }

    const status = e.target.checked;
    setYearlyView(status);

    if (status) {
      setSelectedYearKey(Object.keys(paymentStream.breakdown[selectedGroupKey].payment_streams)[0])
    }
  }

  const enableMonthlyView = (e) => {
    const status = e.target.checked;
    setMonthlyView(status);

    if (status) {
      setSelectedMonthKey(Object.keys(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams)[0])
    }
  }

  const _renderSliderView = () => {
    if (selectedGroupKey && selectedYearKey && yearlyView && monthlyView) {
      return <div>
        <span className="fs-16 fw-600">Selection for 1 month</span>
        <span>
          <span>{monthNames[Number(selectedMonthKey) - 1]}</span>
          <span className="ml-auto">${Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams[selectedMonthKey].present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
        </span>
        <span>
          <RangeSlider
            value={(Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams[selectedMonthKey].selectedAmount) / Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams[selectedMonthKey].present_value) * 100)}
            onChange={(value) => handleOnChangeMonth(Math.round(value))}
            totalAmount={paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams[selectedMonthKey].present_value}
          />
        </span>
      </div>
    }

    if (selectedGroupKey && selectedYearKey && yearlyView) {
      return <div>
        <span className="fs-16 fw-600">Selection for 1 year</span>
        <span>
          <span>{selectedYearKey}</span>
          <span className="ml-auto">${Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
        </span>
        <span>
          <RangeSlider
            value={(Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].selectedAmount) / Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].present_value) * 100)}
            onChange={(value) => handleOnChangeYear(Math.round(value))}
            totalAmount={paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].present_value}
          />
        </span>
      </div>
    }

    return <div>
      <span className="fs-16 fw-600">Selection for 5 years</span>
      <span>
        <span>{selectedGroupKey}</span>
        <span className="ml-auto">${Number(paymentStream.breakdown[selectedGroupKey].present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
      </span>
      <span>
        <RangeSlider
          value={(Number(paymentStream.breakdown[selectedGroupKey].selectedAmount) / Number(paymentStream.breakdown[selectedGroupKey].present_value) * 100)}
          onChange={(value) => handleOnChangeGroup(Math.round(value))}
          selectedAmount={paymentStream.breakdown[selectedGroupKey].selectedAmount}
          totalAmount={paymentStream.breakdown[selectedGroupKey].present_value}
        />
      </span>
    </div>
  }

  const selectAll = (state) => {
    setAllYearsSelected(state.state);
    dispatch(changePaymentStreamSelectedAmount({
      policyId,
      paymentStreamId: state.paymentStreamId,
      value: state.state ? 100 : 0,
      selected: state.state ? true : undefined,
      reset: !state.state ? true : undefined,
    }));
    setYearlyView(false);
    setMonthlyView(false);
  }

  const toggleAllYears = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showAllYearPopup({ paymentStreamId, state: !allYearsSelected });
  }

  const resetSelections = (resetSelection = {}) => {
    setAllYearsSelected(false);
    dispatch(changePaymentStreamSelectedAmount({
      ...resetSelection,
      value: 0,
      reset: true,
    }));
    setYearlyView(false);
    setMonthlyView(false);
  }

  useEffect(() => {
    if (payout && !payout.loading && payout.data && payout.data.policies && payout.data.policies[policyId] && payout.data.policies[policyId].payment_streams && payout.data.policies[policyId].payment_streams[paymentStreamId]) {
      setPaymentStream(payout.data.policies[policyId].payment_streams[paymentStreamId]);
      setLoaded(true);
    }
  }, [payout, policyId, paymentStreamId]);

  useEffect(() => {
    if (loaded) {
      setSelectedGroupKey(Object.keys(paymentStream.breakdown)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    if (allYearSelection && allYearSelection.paymentStreamId === paymentStreamId && allYearSelection.state !== undefined) {
      selectAll(allYearSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allYearSelection]);

  useEffect(() => {
    if (resetSelection) {
      resetSelections(resetSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSelection]);

  useEffect(() =>{
    if (open && !allYearsSelected) {
      setAllYearsSelected(paymentStream && paymentStream.selected && Number(paymentStream.selectedAmount) === Number(paymentStream.presentValue));
    }
  }, [allYearsSelected, open, paymentStream]);

  return <>
    <div>
      <div className={`ps-b ${open ? 'show' : ''}`}>
        <div className="ps-b-h">
          <div className="content">
            <span>
              <button className="btn btn-back" onClick={onClose}><SlArrowDown size={22} /></button>
            </span>
            <span>
              <span>
                <span className="fs-18 fw-600">Selected: ${Number(paymentStream?.selectedAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} from this payment stream</span>
                <span><button className="btn btn-details" onClick={() => showInfoPopup('slider-info')}><LiaInfoCircleSolid size={20} style={{ marginRight: 5 }} /> Details</button></span>
              </span>
              <span>
                <span className="fs-18 fw-600">Total available: ${Number(paymentStream?.present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                <span><button className="btn btn-details" onClick={() => showInfoPopup('stream-present-value')}><LiaInfoCircleSolid size={20} style={{ marginRight: 5 }} /> Details</button></span>
              </span>
            </span>
          </div>
        </div>
        <div className="ps-b-b">
          {paymentStream && paymentStream.breakdown &&
            <div>
              <span>
                <span className={`checkbox label-right ${allYearsSelected ? 'selected' : ''}`}>
                  <input
                    id={`input-select-all-years-${paymentStreamId}`}
                    type="checkbox"
                    name="allPSYears"
                    onChange={toggleAllYears}
                    checked={allYearsSelected}
                  />
                  <label htmlFor={`input-select-all-years-${paymentStreamId}`}>ALL YEARS</label>
                </span>
                <span><button className="btn btn-reset fs-18 fw-600" onClick={() => showResetPopup({ policyId, paymentStreamId })}>Reset</button></span>
              </span>

              <span>
                <ul className="year-groups">
                  {Object.keys(paymentStream.breakdown).map(key =>
                    <li key={`year-group-${key}`} className={`${key === selectedGroupKey && !allYearsSelected ? 'selected' : ''}`} onClick={() => onSelectPSYearGroup(key)}>
                      <span className="fs-14">${Number(paymentStream.breakdown[key].present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                      <span className="fs-18 fw-600">{groupKeyLabel(key)}</span>
                      <span>
                        <span className="ps-progress-bar"><span className="track" style={{ width: `${(Number(paymentStream.breakdown[key].selectedAmount) / Number(paymentStream.breakdown[key].present_value)) * 100}%` }}></span></span>
                      </span>
                    </li>
                  )}
                </ul>
                {!([CONSTANTS.paymentFrequencies.LUMP_SUM, CONSTANTS.paymentFrequencies.LumpSum, CONSTANTS.paymentFrequencies.lump_sum, CONSTANTS.paymentFrequencies.lumpsum].includes(paymentStream.intervalType)) &&
                  <div className="view-controller">
                    <span className={`switch-control ml-auto ${yearlyView ? 'checked' : ''}`}>
                      <label htmlFor={`toggle-switch-yearly-view-${paymentStreamId}`}>Enable yearly view
                        <input id={`toggle-switch-yearly-view-${paymentStreamId}`} type="checkbox" onChange={enableYearlyView} checked={yearlyView} /><span></span>
                      </label>
                    </span>
                  </div>
                }
                {yearlyView &&
                  <ul className="years">
                    {Object.keys(paymentStream.breakdown[selectedGroupKey].payment_streams).map(key =>
                      <li key={`year-${key}`} className={`${key === selectedYearKey && !allYearsSelected ? 'selected' : ''}`} onClick={() => onSelectPSYear(key)}>
                        <span className="fs-14">${Number(paymentStream.breakdown[selectedGroupKey].payment_streams[key].present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                        <span className="fs-18 fw-600">{key}</span>
                        <span>
                          <span className="ps-progress-bar"><span className="track" style={{ width: `${(Number(paymentStream.breakdown[selectedGroupKey].payment_streams[key].selectedAmount) / Number(paymentStream.breakdown[selectedGroupKey].payment_streams[key].present_value)) * 100}%` }}></span></span>
                        </span>
                      </li>
                    )}
                  </ul>
                }
                {['1week', '2weeks', '1month', '2months', '3months'].includes(paymentStream.intervalType) && <>
                  {yearlyView &&
                    <div className="view-controller">
                      <span className={`switch-control ml-auto ${monthlyView ? 'checked' : ''}`}>
                        <label htmlFor={`toggle-switch-monthly-view-${paymentStreamId}`}>Enable monthly view
                          <input id={`toggle-switch-monthly-view-${paymentStreamId}`} type="checkbox" onChange={enableMonthlyView} checked={monthlyView} disabled={allYearsSelected} /><span></span>
                        </label>
                      </span>
                    </div>
                  }
                  {monthlyView &&
                    <ul className="months">
                      {Object.keys(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams).map(key =>
                        <li key={`month-${key}`} className={`${key === selectedMonthKey ? 'selected' : ''}`} onClick={() => onSelectPSMonth(key)}>
                          <span className="fs-14">${Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams[key].present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                          <span className="fs-18 fw-600">{monthNames[Number(key) - 1]}</span>
                          <span>
                            <span className="ps-progress-bar"><span className="track" style={{ width: `${(Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams[key].selectedAmount) / Number(paymentStream.breakdown[selectedGroupKey].payment_streams[selectedYearKey].payment_streams[key].present_value)) * 100}%` }}></span></span>
                          </span>
                        </li>
                      )}
                    </ul>
                  }
                </>
                }
              </span>
            </div>
          }
          {paymentStream && paymentStream.breakdown && selectedGroupKey && _renderSliderView()}
        </div>
      </div>
    </div>
  </>
}

export default PaymentStreamBreakdown;