import React from "react";
import { useSelector } from "react-redux";

import PaymentStreamCard from "./PaymentStreamCard";

const PaymentStreamList = ({
  policyId = null,
  showDetails,
  showBreakdown,
  showInfoPopup,
  showResetPopup,
  resetSelection,
  showAllYearPopup,
  allYearSelection,
}) => {
  const payout = useSelector(state => state.payout);

  return <div>
    <span>Tap payment stream to edit selection</span>
    <div className="ps">
      {payout && payout.data && payout.data.policies && payout.data.policies[policyId] && Object.keys(payout.data.policies[policyId].payment_streams).map((key, index) =>
        <PaymentStreamCard
          key={`policy-${key}`}
          index={index}
          policyId={policyId}
          paymentStreamId={key}
          showDetails={(psId) => showDetails(psId, index)}
          showBreakdown={(psId) => showBreakdown(psId)}
          showInfoPopup={showInfoPopup}
          showResetPopup={showResetPopup}
          resetSelection={resetSelection}
          showAllYearPopup={showAllYearPopup}
          allYearSelection={allYearSelection}
        />
      )}
    </div>
  </div>;
}

export default PaymentStreamList;