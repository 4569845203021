import Request from "./request";

const fetchPayoutData = () => {
  return Request.get(`/payouts/payout-data`);
};

const submitPayoutData = (data) => {
  return Request.post(`/payouts`, { ...data });
};

const PayoutService = {
  fetchPayoutData,
  submitPayoutData,
};

export default PayoutService;