import React from "react";

import Popup from "../Popup";
import CONSTANTS from "../../utils/constants";

const InfoPopup = ({ show, popupId, onClose }) => {
  return (
    <Popup show={show ? true : false} style={{ textAlign: 'center' }}>
      <Popup.Dialog>
        <Popup.Content style={{ maxWidth: 360 }}>
          <Popup.Header>
            <span className="fs-18 fw-600 mx-auto">COMPD</span>
          </Popup.Header>
          <Popup.Body className="p-t-0">
            <p className="fs-18 m-0">{CONSTANTS.infoPopupContents[popupId] ? CONSTANTS.infoPopupContents[popupId] : ""}</p>
          </Popup.Body>
          <Popup.Footer style={{ borderTop: '1px solid #989898' }}>
            <button className="btn btn-reset fs-22 fw-600 mx-auto" style={{ width: '100%' }} onClick={onClose}>ok</button>
          </Popup.Footer>
        </Popup.Content>
      </Popup.Dialog>
    </Popup>
  );
}

export default InfoPopup;