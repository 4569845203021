import React, { useState, useEffect, useRef } from "react";

const RangeSlider = ({ value = 0, onChange, totalAmount = 0 }) => {
  const rangeTooltip = useRef();
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState(value);

  const positionTooltip = (value) => {
    if (!rangeTooltip || !rangeTooltip.current) return;
    const left = ((Number(rangeTooltip.current.offsetParent.clientWidth) - Number(rangeTooltip.current.clientWidth)) * Number(value)) / 100;

    setTooltipPosition(`${left}px`);
  }

  const handleOnChange = (e) => {
    positionTooltip(e.target.value);
    setSliderValue(e.target.value);
    setSelectedAmount((Math.round(e.target.value) * Number(totalAmount) / 100));
    onChange(e.target.value);
  }

  useEffect(() => {
    positionTooltip(value);
    setSliderValue(value);
    setSelectedAmount((Math.round(value) * Number(totalAmount) / 100));
  }, [totalAmount, value]);

  return (
    <div className="range-control" style={{ width: '100%' }}>
      <input
        type="range"
        min="0"
        max="100"
        step="1"
        style={{ width: '100%' }}
        onChange={handleOnChange}
        value={sliderValue}
      />
      <output ref={rangeTooltip} style={{ left: `${tooltipPosition}` }}>{Math.round(sliderValue)}% :${Number(selectedAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</output>
      <mark className="first-mark">Nil</mark>
      <mark>50%</mark>
      <mark className="last-mark">Full</mark>
    </div>
  );
}

export default RangeSlider;