import React from "react";

import { SlArrowLeft } from "react-icons/sl";
import { GoHomeFill } from "react-icons/go";

import { Bridge } from "../../utils/bridge";

const backAction = {
  action: "close",
  payload: null,
}

const Header = () => {
  const handleOnBack = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Bridge(backAction);
  }

  return <div>
    <div className="toolbar">
      <span>
        <button className="btn btn-back" onClick={handleOnBack}><SlArrowLeft size={22} style={{ marginRight: 12 }} /> Payout Simulator</button>
      </span>
      <span>
        <button className="btn btn-icon btn-back" onClick={handleOnBack}><GoHomeFill size={24} /></button>
      </span>
    </div>
  </div>;
}

export default Header;