import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { fetchPayout, submitPayout } from "../store/slices/payout";
import { Bridge } from '../utils/bridge';
import CONSTANTS from '../utils/constants';

import LoadingState from '../components/LoadingState';
import Popup from '../components/Popup';
import Header from '../components/PayoutSimulator/Header';
import PolicyEmptyView from '../components/PayoutSimulator/PolicyEmptyView';
import PolicyList from '../components/PayoutSimulator/PolicyList';
import PolicyDetails from '../components/PayoutSimulator/PolicyDetails';
import Summary from "../components/PayoutSimulator/Summary";
import PolicyOverview from '../components/PayoutSimulator/PolicyOverview';
import InfoPopup from '../components/InfoPopup';

function Home() {
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(null);
  const [showResetPopup, setShowResetPopup] = useState(null);
  const [resetSelection, setResetSelection] = useState(null);
  const [policyOverview, setPolicyOverview] = useState(null);
  const [showAllYearPopup, setShowAllYearPopup] = useState(null);
  const [allYearSelection, setAllYearSelection] = useState(null);

  const payout = useSelector(state => state.payout);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const initFetch = useCallback(() => {
    if (user && user.accessToken) {
      dispatch(fetchPayout());
    }
  }, [dispatch, user]);

  useEffect(() => {
    initFetch()
  }, [initFetch]);

  const onClickPolicy = (policy) => {
    Bridge({
      action: "payment-stream-view-open",
      payload: null,
    });
    setSelectedPolicy({ ...policy });
    setShowPolicyDetails(true);
  }

  const handleOnClosePolicyDetails = () => {
    Bridge({
      action: "payment-stream-view-close",
      payload: null,
    });
    setShowPolicyDetails(false);
  }

  const handleResetSelection = () => {
    setResetSelection(showResetPopup);
    setShowResetPopup(null);
  }

  const handleAllYearSelection = () => {
    setAllYearSelection(showAllYearPopup);
    setShowAllYearPopup(null);
  }

  const handleShowInfo = (popupId) => {
    setShowInfoPopup(popupId);
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const policies = { ...payout.data.policies };
    const data = {
      selectedAmount: payout.requestingAmount,
      adjustedAmount: payout.adjustedAmount,
      adjustedType: payout.adjustedType,
      policies: [],
    };

    for (const p of Object.keys(policies)) {
      const paymentStreams = [];
      for (const ps of Object.keys(policies[p].payment_streams)) {
        if (Number(policies[p].payment_streams[ps].selectedAmount) > 0) {
          const payments = [];

          for (const payment of policies[p].payment_streams[ps].payments) {
            if (Number(payment.selectedAmount) > 0) {
              payments.push(payment);
            }
          }

          if (payments.length > 0) {
            paymentStreams.push({
              id: ps,
              presentValue: Number(policies[p].payment_streams[ps].present_value),
              selectedAmount: policies[p].payment_streams[ps].selectedAmount,
              payments,
            });
          }
        }
      }

      if (Number(policies[p].selectedAmount) > 0 && paymentStreams.length > 0) {
        data.policies.push({
          id: p,
          presentValue: Number(policies[p].present_value),
          selectedAmount: policies[p].selectedAmount,
          paymentStreams,
        });
      }
    }

    dispatch(submitPayout({ data }))
      .unwrap()
      .then(response => {
        Bridge({ action: "summary", payload: response.data.result });
      })
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <div id="payout-simulator" className="container">
      <div className="header">
        <Header />
      </div>
      <div className="body">
        {!payout.loading && payout.data && payout.data.policies && Object.keys(payout.data.policies).length > 0 &&
          <PolicyList
            onClickPolicy={onClickPolicy}
            showInfoPopup={handleShowInfo}
            setPolicyOverview={(policy) => setPolicyOverview(policy)}
          />
        }
        {!payout.loading && (!payout.data || !payout.data.policies || Object.keys(payout.data.policies).length <= 0) &&
          <PolicyEmptyView />
        }
      </div>
      <div className="footer">
        <Summary handleOnSubmit={handleOnSubmit} />
      </div>

      <PolicyDetails
        policyId={selectedPolicy ? selectedPolicy.id : null}
        open={showPolicyDetails}
        onClose={handleOnClosePolicyDetails}
        showInfoPopup={handleShowInfo}
        showResetPopup={(payload) => setShowResetPopup(payload)}
        resetSelection={resetSelection}
        showAllYearPopup={(payload) => setShowAllYearPopup(payload)}
        allYearSelection={allYearSelection}
      />

      <LoadingState
        show={payout.loading || payout.submitting}
      />

      <PolicyOverview
        show={policyOverview ? true : false}
        onClose={() => setPolicyOverview(null)}
        policy={policyOverview}
      />

      <InfoPopup
        show={showInfoPopup ? true : false}
        popupId={showInfoPopup}
        onClose={() => setShowInfoPopup(null)}
      />

      <Popup show={showResetPopup ? true : false} style={{ textAlign: 'center' }}>
        <Popup.Dialog>
          <Popup.Content style={{ maxWidth: 360 }}>
            <Popup.Body style={{ padding: '2rem 1rem 1rem' }}>
              <p className="fs-18 m-0">{CONSTANTS.infoPopupContents['reset-info']}</p>
            </Popup.Body>
            <Popup.Footer style={{ padding: '1rem 3rem 2rem', flexDirection: 'column', gap: '1.5rem' }}>
              <button className="btn btn-popup-close btn-filled fs-20 mx-auto" style={{ width: '100%' }} onClick={() => handleResetSelection()}>Reset</button>
              <button className="btn btn-popup-close fs-20 mx-auto" style={{ width: '100%' }} onClick={() => setShowResetPopup(null)}>Cancel</button>
            </Popup.Footer>
          </Popup.Content>
        </Popup.Dialog>
      </Popup>

      <Popup show={showAllYearPopup ? true : false} style={{ textAlign: 'center' }}>
        <Popup.Dialog>
          <Popup.Content style={{ maxWidth: 360 }}>
            <Popup.Body style={{ padding: '2rem 1rem 1rem' }}>
              {showAllYearPopup?.state === true && <p className="fs-18 m-0">{CONSTANTS.infoPopupContents['all-years-checked']}</p>}
              {showAllYearPopup?.state === false && <p className="fs-18 m-0">{CONSTANTS.infoPopupContents['all-years-unchecked']}</p>}
            </Popup.Body>
            <Popup.Footer style={{ padding: '1rem 3rem 2rem', flexDirection: 'column', gap: '1.5rem' }}>
              <button className="btn btn-popup-close btn-filled fs-20 mx-auto" style={{ width: '100%' }} onClick={() => handleAllYearSelection()}>Reset my Selection</button>
              <button className="btn btn-popup-close fs-20 mx-auto" style={{ width: '100%' }} onClick={() => setShowAllYearPopup(null)}>Keep my Selection</button>
            </Popup.Footer>
          </Popup.Content>
        </Popup.Dialog>
      </Popup>
    </div>
  )
}
export default Home;