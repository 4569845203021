import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SlArrowLeft } from "react-icons/sl";
import { GoHomeFill } from "react-icons/go";
import { LiaInfoCircleSolid } from "react-icons/lia";

import { updatePolicy } from "../../store/slices/payout";

import PaymentStreamList from "./PaymentStreamList";
import PaymentStreamDetails from "./PaymentStreamDetails";

const PolicyDetails = ({
  policyId = null,
  open = false,
  onClose,
  showInfoPopup,
  showResetPopup,
  resetSelection,
  showAllYearPopup,
  allYearSelection,
}) => {
  const payout = useSelector(state => state.payout);
  const dispatch = useDispatch();

  const [policy, setPolicy] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [paymentStream, setPaymentStream] = useState(null);

  const handleShowDetails = (psId, index) => {
    setPaymentStream({ ...policy.payment_streams[psId], index: index + 1 });
    setShowDetails(true);
  }

  const onSelectAllPaymentStreams = () => {
    const tempPolicy = { ...policy };
    tempPolicy.selected = true;
    tempPolicy.selectedAmount = Number(policy.present_value);

    dispatch(
      updatePolicy({ ...tempPolicy })
    );
  }

  const onResetSelectPaymentStream = () => {
    const tempPolicy = { ...policy };
    tempPolicy.selected = false;
    tempPolicy.selectedAmount = 0;

    dispatch(
      updatePolicy({ ...tempPolicy })
    );
  }

  useEffect(() => {
    if (payout && !payout.loading && payout.data && payout.data.policies) {
      setPolicy(payout.data.policies[policyId]);
    }
  }, [payout, policyId]);

  return <div>
    <div className={`p-d ${open ? 'show' : ''}`}>
      {payout && !payout.loading && payout.data && payout.data.policies &&
        <div className="p-d-h">
          <div className="toolbar">
            <span>
              <button className="btn btn-back" onClick={onClose}><SlArrowLeft size={22} style={{ marginRight: 12 }} /> Payout Simulator</button>
            </span>
            <span>
              <button className="btn btn-icon btn-back" onClick={onClose}><GoHomeFill size={24} /></button>
            </span>
          </div>

          <div className="content">
            <span>
              <span className="policy-case-number fs-14">#{policy?.caseNumber ? policy.caseNumber : ''}</span>
              <span className="policy-provider-logo" style={{ backgroundImage: `url(${policy?.policyProvider?.logo ? policy?.policyProvider?.logo : ''})` }}></span>
              <span className="policy-provider-name text-ellipsis fs-14">{policy?.policyProvider?.name ? policy.policyProvider.name : ''}</span>
              <button className="btn btn-reset fw-100 fs-14">See Details</button>
            </span>
            <span>
              <span>Policy Value: <button className="btn btn-details" onClick={() => showInfoPopup('policy-value')}><LiaInfoCircleSolid size={20} style={{ marginRight: 5 }} /> Details</button></span>
              <span className="policy-present-value">${policy?.present_value ? Number(policy.present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</span>
              <span className="policy-payment-streams">Streams in this policy: <span className="ps-count">{policy?.payment_streams ? String(Object.keys(policy.payment_streams).length).padStart(2, '0') : 0}</span></span>
            </span>
            <span>
              <button className="btn btn-filled fw-100 fs-12" onClick={() => onSelectAllPaymentStreams(policy.id)}>Add All</button>
              <button className="btn btn-filled fw-100 fs-12" onClick={() => onResetSelectPaymentStream(policy.id)}>Clear</button>
            </span>
          </div>
        </div>
      }
      <div className="p-d-b">
        {policy && policy.payment_streams &&
          <PaymentStreamList
            policyId={policy.id}
            showDetails={handleShowDetails}
            showInfoPopup={showInfoPopup}
            showResetPopup={showResetPopup}
            resetSelection={resetSelection}
            showAllYearPopup={showAllYearPopup}
            allYearSelection={allYearSelection}
          />
        }
      </div>
    </div>

    <PaymentStreamDetails
      show={showDetails}
      onClose={() => setShowDetails(false)}
      paymentStream={paymentStream}
    />
  </div>;
}

export default PolicyDetails;