export const Bridge = (payload) => {
  if (window.NativeJavascriptInterface) {
    window.NativeJavascriptInterface.postMessage(JSON.stringify(payload));
  } else if (window.webkit && window.webkit.messageHandlers) {
    try {
      window.webkit.messageHandlers.NativeJavascriptInterface.postMessage(payload);
    }
    catch (err) {
      console.log(err.message);
    }
  } else {
    window.parent.postMessage(JSON.stringify(payload), '*');
  }
} 