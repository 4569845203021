import React from "react";

import { Bridge } from "../../utils/bridge";


const PolicyEmptyView = () => {
  const handleOnBack = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Bridge({
      action: "create-policy",
      payload: null,
    });
  }

  const handleOpenChat = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Bridge({
      action: "open-chat",
      payload: null,
    });
  }

  return <div className="empty-policy-view">
    <span>
      <p className="fs-18">No policy in payout simulator yet.<br />Create a policy to proceed using the payout simulator.</p>
      <div><button className="btn btn-filled" onClick={handleOnBack}>Create Policy</button></div>
      <div><button className="btn btn-reset fs-18 fw-500" onClick={handleOpenChat}>Help me understand the<br />payout simulator</button></div>
    </span>
  </div>;
}

export default PolicyEmptyView;