import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PayoutService from "../../services/payout";
import helpers from "../../utils/helper";

const initialState = {
  loading: true,
  data: null,
  requestingAmount: 0,
  adjustedAmount: 0,
  adjustedType: null,
  error: null,
  submitting: false,
};

export const fetchPayout = createAsyncThunk(
  "payout/fetch",
  async () => {
    const res = await PayoutService.fetchPayoutData();
    return res.data;
  }
);

export const submitPayout = createAsyncThunk(
  "payout/submit",
  async (data) => {
    const res = await PayoutService.submitPayoutData(data);
    return res.data;
  }
);

const payoutSlice = createSlice({
  name: "payout",
  initialState,
  reducers: {
    selectAllPolicies: (state, action) => {
      const payout = { ...state.data };

      for (const p of Object.keys(payout.policies)) {
        payout.policies[p].selected = true;
        payout.policies[p].selectedAmount = Number(payout.policies[p].present_value);
        payout.policies[p] = { ...helpers.policyPaymentStreams(payout.policies[p], true) };
      }

      state.data = { ...payout };

      const { total, adjusted, adjustedType } = helpers.calculateRequestingAmount(payout);
      state.requestingAmount = total;
      state.adjustedAmount = adjusted;
      state.adjustedType = adjustedType;
    },
    deselectAllPolicies: (state, action) => {
      const payout = { ...state.data };

      for (const p of Object.keys(payout.policies)) {
        payout.policies[p].selected = false;
        payout.policies[p].selectedAmount = 0;
        payout.policies[p] = { ...helpers.policyPaymentStreams(payout.policies[p], false) };
      }

      state.data = { ...payout };

      const { total, adjusted, adjustedType } = helpers.calculateRequestingAmount(payout);
      state.requestingAmount = total;
      state.adjustedAmount = adjusted;
      state.adjustedType = adjustedType;
    },
    updatePolicy: (state, action) => {
      const payout = { ...state.data };
      const policy = { ...action.payload };
      payout.policies[policy.id] = { ...helpers.policyPaymentStreams(policy, policy.selected) };
      state.data = { ...payout };

      const { total, adjusted, adjustedType } = helpers.calculateRequestingAmount(payout);
      state.requestingAmount = total;
      state.adjustedAmount = adjusted;
      state.adjustedType = adjustedType;
    },
    selectPaymentStream: (state, action) => {
      const { policyId, paymentStreamId, selectOnly } = action.payload;
      const payout = { ...state.data };

      const policy = { ...payout.policies[policyId] };
      const isAmountSelected = Number(policy.payment_streams[paymentStreamId].selectedAmount) > 0;

      policy.payment_streams[paymentStreamId].selected = true;

      if (!selectOnly && !isAmountSelected) {
        policy.payment_streams[paymentStreamId].selectedAmount = Number(policy.payment_streams[paymentStreamId].present_value);
        policy.payment_streams[paymentStreamId].payments = policy.payment_streams[paymentStreamId].payments.map(p => { return { ...p, selectedAmount: Number(p.present_value) } })
        policy.payment_streams[paymentStreamId].breakdown = helpers.paymentStreamBreakdown(policy.payment_streams[paymentStreamId]);
      }

      let totalPolicyAmount = 0;
      for (let ps of Object.keys(policy.payment_streams)) {
        if (policy.payment_streams[ps].selected) {
          totalPolicyAmount += Number(policy.payment_streams[ps].selectedAmount);
        }
      }

      policy.selected = true;
      policy.selectedAmount = totalPolicyAmount;

      payout.policies[policyId] = policy;
      state.data = { ...payout };

      const { total, adjusted, adjustedType } = helpers.calculateRequestingAmount(payout);
      state.requestingAmount = total;
      state.adjustedAmount = adjusted;
      state.adjustedType = adjustedType;
    },
    deselectPaymentStream: (state, action) => {
      const { policyId, paymentStreamId } = action.payload;
      const payout = { ...state.data };

      const policy = { ...payout.policies[policyId] };

      policy.payment_streams[paymentStreamId].selected = false;
      policy.payment_streams[paymentStreamId].selectedAmount = 0;
      policy.payment_streams[paymentStreamId].payments = policy.payment_streams[paymentStreamId].payments.map(p => { return { ...p, selectedAmount: 0 } })
      // policy.payment_streams[paymentStreamId].breakdown = helpers.paymentStreamBreakdown(policy.payment_streams[paymentStreamId]);

      let totalPolicyAmount = 0;
      let policySelected = false;
      for (let ps of Object.keys(policy.payment_streams)) {
        if (policy.payment_streams[ps].selected) {
          totalPolicyAmount += Number(policy.payment_streams[ps].selectedAmount);
          policySelected = true;
        }
      }

      policy.selected = policySelected;
      policy.selectedAmount = totalPolicyAmount;

      payout.policies[policyId] = { ...policy };
      state.data = { ...payout };

      const { total, adjusted, adjustedType } = helpers.calculateRequestingAmount(payout);
      state.requestingAmount = total;
      state.adjustedAmount = adjusted;
      state.adjustedType = adjustedType;
    },
    changePaymentStreamSelectedAmount: (state, action) => {
      const { policyId, paymentStreamId, groupKey, yearKey, monthKey, value, selected, reset } = action.payload;

      const payout = { ...state.data };
      const policy = { ...payout.policies[policyId] };
      const paymentStream = { ...policy.payment_streams[paymentStreamId] };

      let paymentIds = [];
      if (monthKey) {
        paymentIds = paymentStream.breakdown[groupKey].payment_streams[yearKey].payment_streams[monthKey].payment_ids;
      } else if (yearKey) {
        paymentIds = paymentStream.breakdown[groupKey].payment_streams[yearKey].payment_ids;
      } else if (groupKey) {
        paymentIds = paymentStream.breakdown[groupKey].payment_ids;
      } else {
        paymentIds = [...Array(paymentStream.payments.length).keys()];
      }

      for (let index of paymentIds) {
        paymentStream.payments[index].selectedAmount = (Number(value) * Number(paymentStream.payments[index].present_value)) / 100;
      }

      if (reset) {
        paymentStream.selected = false;
      }

      if (selected) {
        paymentStream.selected = true;
      }

      paymentStream.breakdown = helpers.paymentStreamBreakdown(paymentStream);
      let totalPSAmount = 0;
      for (const p of paymentStream.payments) {
        totalPSAmount += Number(p.selectedAmount);
      }
      paymentStream.selectedAmount = totalPSAmount;

      policy.payment_streams[paymentStreamId] = { ...paymentStream };

      let totalPolicyAmount = 0;
      let policySelected = false;
      for (let ps of Object.keys(policy.payment_streams)) {
        if (policy.payment_streams[ps].selected) {
          totalPolicyAmount += Number(policy.payment_streams[ps].selectedAmount);
          policySelected = true;
        }
      }

      policy.selected = policySelected;
      policy.selectedAmount = totalPolicyAmount;

      payout.policies[policyId] = policy;
      state.data = { ...payout };

      const { total, adjusted, adjustedType } = helpers.calculateRequestingAmount(payout);
      state.requestingAmount = total;
      state.adjustedAmount = adjusted;
      state.adjustedType = adjustedType;
    }
  },
  extraReducers: {
    [fetchPayout.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPayout.fulfilled]: (state, action) => {
      const payout = { ...action.payload.data };
      const tempPolicies = {};

      if (payout && payout.policies && Object.keys(payout.policies).length > 0) {
        for (const p of Object.keys(payout.policies)) {
          tempPolicies[p] = {
            ...helpers.policyPaymentStreams(payout.policies[p], false),
            selected: false,
            selectedAmount: 0,
          };
        }
      }

      payout.policies = { ...tempPolicies };
      state.data = { ...payout };
      state.loading = false;
    },
    [fetchPayout.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [submitPayout.pending]: (state, action) => {
      state.submitting = true;
    },
    [submitPayout.fulfilled]: (state, action) => {
      state.submitting = false;
    },
    [submitPayout.rejected]: (state, action) => {
      state.submitting = false;
      state.error = action.error.message;
    },
  },
});

export const {
  updatePolicy,
  selectAllPolicies,
  deselectAllPolicies,
  selectPaymentStream,
  deselectPaymentStream,
  changePaymentStreamSelectedAmount,
} = payoutSlice.actions;
export default payoutSlice.reducer;