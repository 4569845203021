import axios from "axios";
import store from "../store";

const Request = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  headers: {
    "Content-type": "application/json",
  }
});

Request.interceptors.request.use(function (config) {
  const { accessToken } = store.getState().user;
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
  return config;
});

export default Request;