import React from "react";
import moment from "moment";

import CONSTANTS from "../../utils/constants";
import Popup from "../Popup";

const PolicyOverview = ({ show = false, onClose, policy = null }) => {

  const paymentDates = (paymentStream = null) => {
    if (!paymentStream || !paymentStream.policyType) return null;

    switch (paymentStream.policyType) {
      case 'GUAR_ANN':
        return `${moment(paymentStream.startDate).format('MM/DD/YYYY')} to ${moment(paymentStream.endDate).format('MM/DD/YYYY')}`;
      case 'LC_ANN':
      case 'GUAR_LC_ANN':
        return `${moment(paymentStream.startDate).format('MM/DD/YYYY')} to For Life`;
      default:
        return null;
    }
  }

  return (
    <Popup show={show}>
      <Popup.Dialog>
        <Popup.Content className="p-o fs-18">
          <Popup.Header style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', gap: '0.5rem' }}>
            <span>Policy: <span className="fw-600">{policy ? policy.caseNumber : ''}</span></span>
            <span>Total Value: <span className="fw-600">${policy ? parseFloat(policy.present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00'}</span></span>
          </Popup.Header>
          {policy && policy.payment_streams && Object.keys(policy.payment_streams).length > 0 && Object.keys(policy.payment_streams).map((psId) => {
            return <Popup.Body key={`p-o-ps-${psId}`} style={{ borderTop: '1px solid #989898', display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>PSt: <span className="fw-600">{policy.payment_streams[psId].policyStreamNumber}</span></span>
                <span>$: <span className="fw-600">{parseFloat(policy.payment_streams[psId].present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></span>
              </div>
              <div>From: <span className="fw-600">{paymentDates(policy.payment_streams[psId])}</span></div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="fw-600">{policy.payment_streams[psId].intervalType ? CONSTANTS.paymentFrequencies[policy.payment_streams[psId].intervalType] : ''} payouts</div>
                <div className="fs-16">{policy.payment_streams[psId].payments.length} payments</div>
              </div>
            </Popup.Body>
          })}
          {/* <div className="ps-d">
              <div className="ps-d-table">
                <div className="ps-d-row">
                  <div className="ps-d-col"><PaymentTypeIcon /> Payment type</div>
                  <div className="ps-d-col">{paymentStream?.policyType ? CONSTANTS.paymentStreamTypes[paymentStream.policyType] : ''}</div>
                </div>
                <div className="ps-d-row">
                  <div className="ps-d-col"><CalendarIcon size={22} /> Payment dates</div>
                  <div className="ps-d-col">{paymentDates()}</div>
                </div>
                {paymentStream?.policyType && paymentStream.policyType === 'GUAR_LC_ANN' && <div className="ps-d-row">
                  <div className="ps-d-col"><GuaranteeDateIcon /> Guarantee date</div>
                  <div className="ps-d-col">{moment(paymentStream.endDate).format('MM/DD/YYYY')}</div>
                </div>}
                <div className="ps-d-row">
                  <div className="ps-d-col"><PaymentFrequencyIcon /> Payment frequency</div>
                  <div className="ps-d-col">{paymentStream?.intervalType ? CONSTANTS.paymentFrequencies[paymentStream.intervalType] : ''}</div>
                </div>
                <div className="ps-d-row">
                  <div className="ps-d-col"><FirstPaymentAmountIcon /> First payment amount</div>
                  <div className="ps-d-col">${Number(paymentStream?.paymentStreamAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                </div>
                <div className="ps-d-row">
                  <div className="ps-d-col"><PaymentIncreaseIcon /> Payments increasing?</div>
                  <div className="ps-d-col">{paymentStream?.increasePayment && paymentStream.increasePayment === true ? 'Yes' : 'No'}</div>
                </div>
                {paymentStream?.increasePayment && paymentStream.increasePayment === true && <>
                  <div className="ps-d-row">
                    <div className="ps-d-col"><PaymentIncreaseDateIcon /> Increase date</div>
                    <div className="ps-d-col">{String(paymentStream?.policyXIncreaseDay).padStart(2, '0')}/{String(paymentStream?.policyXIncreaseMonth).padStart(2, '0')}</div>
                  </div>
                  <div className="ps-d-row">
                    <div className="ps-d-col"><PaymentIncreasePercentIcon /> Increase percentage</div>
                    <div className="ps-d-col">{Number(paymentStream?.policyXIncreaseRate).toFixed(2)}%</div>
                  </div>
                </>}
              </div>
              <p className="fs-14">* You can only sell your life-contingent payments until your 65th birthday. This way here at COMPD, we ensure that you get a good value for money selling your payments and that you are financially protected at an older age.</p>
              <hr />
              <div className="ps-d-table">
                <div className="ps-d-row">
                  <div className="ps-d-col"><TotalValueIcon /> Total Value</div>
                  <div className="ps-d-col">${Number(paymentStream?.present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                </div>
              </div>
              <p className="fs-14">
                <span className="fw-600">How is my Total Value calculated?</span><br />
                Your total present value is calculated based on best interest rates available on the market. COMPD compares thousands of investors to pick the best rate for you.
              </p>
            </div> */}

          <Popup.Footer>
            <button className="btn btn-popup-close fs-20 fw-600 mx-auto" onClick={onClose}>Close</button>
          </Popup.Footer>
        </Popup.Content>
      </Popup.Dialog>
    </Popup>
  );
};

export default PolicyOverview;