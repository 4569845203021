import React from 'react';

const PopupContent = ({ children, className = "", style = {} }) => {
  return (
    <div className={`popup-content ${className}`} style={style}>
      {children}
    </div>
  );
};

export default PopupContent;