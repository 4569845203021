import React, { useState } from 'react';
import { useSelector } from "react-redux";

import { BsArrowRightShort } from "react-icons/bs";
import { LiaInfoCircleSolid } from "react-icons/lia";

import Popup from '../Popup';

const Summary = ({ handleOnSubmit }) => {
  const payout = useSelector(state => state.payout);
  const [showAdjustment, setShowAdjustment] = useState(false);

  const fontSizeClass = () => {
    const len = String(Math.round(payout.requestingAmount)).length;

    if (len >= 9) {
      return 'fs-24';
    } else if (len > 7) {
      return 'fs-28';
    } else if (len > 6) {
      return 'fs-30';
    } else {
      return 'fs-32';
    }
  }

  const selectedPolicies = () => {
    const caseNumbers = {};
    if (payout && !payout.loading && payout.data && payout.data.policies) {
      for (const p of Object.keys(payout.data.policies)) {
        if (payout.data.policies[p].selected === true) {
          caseNumbers[payout.data.policies[p].caseNumber] = [];

          const psIds = Object.keys(payout.data.policies[p].payment_streams);
          for (let i = 0; i < psIds.length; i++) {
            if (payout.data.policies[p].payment_streams[psIds[i]].selected === true) {
              caseNumbers[payout.data.policies[p].caseNumber].push(`Pst ${payout.data.policies[p].payment_streams[psIds[i]].policyStreamNumber}`)
            }
          }
        }
      }
    }

    if (Object.keys(caseNumbers).length === 0) {
      return <span>Select a policy to continue</span>;
    }

    return <ul>
      {Object.keys(caseNumbers).map((p, index) => {
        return <li key={`p-${p}`}>
          <span>{p}</span>
          <span>
            {caseNumbers[p].map((ps, index) => {
              return <span key={`ps-${index}`}>{ps}</span>
            })}
          </span>
        </li>
      })}
    </ul>;
  }

  return <>
    <div className="selected-ps">
      {selectedPolicies()}
    </div>
    <div className="summary">
      <div>
        <span>
          <span className="fw-500">You are Requesting:</span>
          <span className={`fw-500 ${fontSizeClass()}`}>$ {Number(payout.requestingAmount) > 0 ? Number(payout.requestingAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00'}</span>
          <span className="fw-400">Available: ${payout.data?.display_present_value ? Number(payout.data.display_present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00'}</span>
        </span>
        <span>
          <span onClick={() => setShowAdjustment(true)}><LiaInfoCircleSolid size={20} style={{ marginRight: 5, marginLeft: 5 }} /> Amount adjusted</span>
          <span>
            <button
              className="btn btn-summary"
              disabled={(Number(payout.requestingAmount) <= 0 || (Number(payout.requestingAmount) + Number(payout.adjustedAmount)) < Number(payout.data.min_payout_payout_simulator)) ? true : false}
              onClick={handleOnSubmit}
            >
              Summary <BsArrowRightShort size={36} style={{ marginLeft: 5, marginRight: -10 }} />
            </button>
          </span>
        </span>
      </div>

      {payout && payout.data && (Number(payout.requestingAmount) + Number(payout.adjustedAmount)) > 0 && (Number(payout.requestingAmount) + Number(payout.adjustedAmount)) < Number(payout.data.min_payout_payout_simulator) &&
        <span className="min-payout-error-message">Amount added is below the minimum ${payout.data?.min_payout_payout_simulator ? Number(payout.data.min_payout_payout_simulator).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00'}</span>
      }
    </div>
    <Popup show={showAdjustment}>
      <Popup.Dialog>
        <Popup.Content>
          <Popup.Header>
            <span className="fs-18 fw-600">Fee Details</span>
          </Popup.Header>
          <Popup.Body>
            <p className="fs-18 m-0">
              <span className="text-capitalize">{payout?.adjustedType || 'Fee'}</span> of ${Math.abs(Number(payout.adjustedAmount)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} has been deducted.
            </p>
          </Popup.Body>
          <Popup.Footer>
            <button className="btn btn-popup-close fs-20 fw-600 mx-auto" onClick={() => setShowAdjustment(false)}>Close</button>
          </Popup.Footer>
        </Popup.Content>
      </Popup.Dialog>
    </Popup>
  </>;
}

export default Summary;