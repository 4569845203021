import React from "react";

import PopupDialog from "./PopupDialog";
import PopupContent from "./PopupContent";
import PopupHeader from "./PopupHeader";
import PopupBody from "./PopupBody";
import PopupFooter from "./PopupFooter";

const Popup = ({ show = false, children, className = "", style = {} }) => {
  return (
    <div className={`popup ${className} ${show ? 'show' : ''}`} style={style}>
      {children}
    </div>
  );
};

export default Object.assign(Popup, {
  Dialog: PopupDialog,
  Content: PopupContent,
  Header: PopupHeader,
  Body: PopupBody,
  Footer: PopupFooter,
});