import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { LiaInfoCircleSolid } from "react-icons/lia";

import PolicyCard from "./PolicyCard";

import { selectAllPolicies, deselectAllPolicies } from "../../store/slices/payout";

const PolicyList = ({
  onClickPolicy,
  showInfoPopup,
  setPolicyOverview,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const payout = useSelector(state => state.payout);
  const dispatch = useDispatch();

  const onSelectAllPolicies = (e) => {
    if (e.target.checked) {
      dispatch(selectAllPolicies());
    } else {
      dispatch(deselectAllPolicies());
    }
  }

  const onResetSelectPolicy = () => {
    dispatch(deselectAllPolicies());
  }

  useEffect(() => {
    let allPoliciesSelected = true;
    if (payout && payout.data && payout.data.policies) {
      Object.keys(payout.data.policies).forEach((pId) => {
        if (!payout.data.policies[pId].selected) {
          allPoliciesSelected = false;
        }
      });
    }
    setSelectAll(allPoliciesSelected);
  }, [payout]);

  return <div>
    <div className="policies-total-value">
      <span>
        <span>Today all your policies are worth</span> <button className="btn btn-details" onClick={() => showInfoPopup('policy-value')}><LiaInfoCircleSolid size={20} style={{ marginRight: 5 }} /> Details</button>
      </span>
      <span>${Number(payout?.data?.display_present_value ? payout?.data.display_present_value : 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
    </div>
    <div className="toolbar">
      <span><button className="btn btn-reset fw-600" onClick={onResetSelectPolicy}>Reset</button></span>
      <span>
        <span className="checkbox">
          <input
            id="input-select-all"
            type="checkbox"
            name="allPolicies"
            onChange={onSelectAllPolicies}
            checked={!!selectAll}
          />
          <label htmlFor="input-select-all">Select All</label>
        </span>
      </span>
    </div>
    <div className="policies">
      {payout && payout.data && payout.data.policies && Object.keys(payout.data.policies).map((key) =>
        <PolicyCard
          key={`policy-${key}`}
          policyId={payout.data.policies[key].id}
          onClickPolicy={onClickPolicy}
          setPolicyOverview={setPolicyOverview}
        />
      )}
    </div>
  </div>;
}

export default PolicyList;