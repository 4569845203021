import React from 'react';

const PopupFooter = ({ children, className = "", style = {} }) => {
  return (
    <div className={`popup-footer ${className}`} style={style}>
      {children}
    </div>
  );
};

export default PopupFooter;