import { configureStore } from "@reduxjs/toolkit";
import logger from 'redux-logger'

import payoutReducer from "./slices/payout";
import userReducer from "./slices/user";

const reducer = {
  payout: payoutReducer,
  user: userReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: false, //process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})

export default store;