import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { LiaInfoCircleSolid } from "react-icons/lia";
import { CalendarIcon, PencilIcon, PlusIcon } from "../../assets/icons";
import moment from "moment/moment";
import CONSTANTS from "../../utils/constants";

import { selectPaymentStream, deselectPaymentStream, changePaymentStreamSelectedAmount } from "../../store/slices/payout";

import PaymentStreamBreakdown from "./PaymentStreamBreakdown";

const PaymentStreamCard = ({
  index = 0,
  policyId = null,
  paymentStreamId = null,
  showDetails,
  showInfoPopup,
  showResetPopup,
  resetSelection,
  showAllYearPopup,
  allYearSelection,
}) => {
  const payout = useSelector(state => state.payout);
  const dispatch = useDispatch();

  const [paymentStream, setPaymentStream] = useState(null);
  const [showBreakdown, setShowBreakdown] = useState(false);

  useEffect(() => {
    if (payout && !payout.loading && payout.data && payout.data.policies && payout.data.policies[policyId] && payout.data.policies[policyId].payment_streams && payout.data.policies[policyId].payment_streams[paymentStreamId]) {
      setPaymentStream(payout.data.policies[policyId].payment_streams[paymentStreamId]);
    }
  }, [payout, policyId, paymentStreamId]);

  const onSelectPaymentStream = (e) => {
    if (e.target.checked) {
      dispatch(selectPaymentStream({ policyId, paymentStreamId }));
    } else {
      dispatch(deselectPaymentStream({ policyId, paymentStreamId }));
      dispatch(changePaymentStreamSelectedAmount({
        policyId,
        paymentStreamId,
        value: 0,
        reset: true,
      }));
    }
  }

  const addPaymentStream = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(selectPaymentStream({ policyId, paymentStreamId, selectOnly: true }));
    setShowBreakdown(true);
  }

  const adjustFontSize = (value) => {
    const len = String(Math.round(value)).length;

    if (len > 7) {
      return 'fs-18';
    } else if (len > 6) {
      return 'fs-19';
    } else if (len > 5) {
      return 'fs-20';
    } else {
      return 'fs-22';
    }
  }

  if (!paymentStream) return null;

  return paymentStream ?
    <>
      <div className={`ps-s ${paymentStream.selected ? 'selected' : ''}`}>
        <span>
          <span className="checkbox ps-selected-state">
            <input
              id={`input-select-ps-${paymentStreamId}`}
              type="checkbox"
              onChange={onSelectPaymentStream}
              checked={!!paymentStream.selected}
            />
          </span>
        </span>
        <span>
          <span>
            <span>
              <span className="fs-16 fw-600">{paymentStream?.policyStreamNumber}</span>
              <span className="fs-14">{paymentStream?.policyType ? CONSTANTS.paymentStreamTypes[paymentStream.policyType] : ''}</span>
            </span>
            <span className="fs-12"><CalendarIcon size={20} /> {paymentStream?.startDate ? moment(paymentStream.startDate).format('MM/DD/YYYY') : ''}</span>
            <span className="fs-12">
              <span><CalendarIcon size={20} /> {paymentStream?.endDate ? moment(paymentStream.endDate).format('MM/DD/YYYY') : 'For Life'}</span>
              <span style={{ marginLeft: 'auto' }}>
                <button className="btn btn-details fs-14" style={{ padding: 0, marginLeft: 0 }} onClick={() => showDetails(paymentStream._id)}><LiaInfoCircleSolid size={20} style={{ marginRight: 5 }} /> Details</button>
              </span>
            </span>
          </span>
        </span>
        <span>
          <span onClick={() => setShowBreakdown(true)}>
            <span>
              <div className="fs-12">Added:</div>
              <div className={`fw-500 ${adjustFontSize(paymentStream?.selectedAmount)}`}>${Number(paymentStream?.selectedAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
              <div>
                <span className="ps-progress-bar"><span className="track" style={{ width: `${paymentStream?.selectedAmount && Number(paymentStream.selectedAmount) > 0 ? Number(paymentStream.selectedAmount) / Number(paymentStream.present_value) * 100 : 0}%` }}></span></span>
              </div>
            </span>
            <span>
              <div className="fs-12">Total Value:</div>
              <div className="fs-16">${paymentStream?.present_value ? Number(paymentStream.present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</div>
            </span>

            {!paymentStream.selected && <button className="btn btn-icon" onClick={(e) => addPaymentStream(e)}><PlusIcon size={18} /></button>}
            {paymentStream.selected && <button className="btn btn-icon"><PencilIcon size={18} /></button>}
          </span>
        </span>
      </div>
      <PaymentStreamBreakdown
        policyId={policyId}
        paymentStreamId={paymentStreamId}
        open={showBreakdown}
        onClose={() => setShowBreakdown(false)}
        showInfoPopup={showInfoPopup}
        showResetPopup={showResetPopup}
        resetSelection={resetSelection}
        showAllYearPopup={showAllYearPopup}
        allYearSelection={allYearSelection}
      />
    </> : null;
}

export default PaymentStreamCard;