import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Draggable from 'react-draggable';

import { LiaInfoCircleSolid } from "react-icons/lia";

import { updatePolicy } from "../../store/slices/payout";

const PolicyCard = ({
  policyId = null,
  onClickPolicy,
  setPolicyOverview,
}) => {
  const payout = useSelector(state => state.payout);
  const dispatch = useDispatch();

  const [policy, setPolicy] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [lastDelta, setLastDelta] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });


  const handleOnDrag = (e, data) => {
    if (data.x < -200 || data.x > 0) return false;

    if (lastDelta > data.x) {
      setPosition({ x: -270, y: 0 });
    } else {
      setPosition({ x: 0, y: 0 });
    }

    setLastDelta(data.x);
    setDragging(true);
  }

  const handleOnClickPolicy = (e) => {
    onClickPolicy(policy);
  }

  const handleOnStop = (...agrs) => {
    if (dragging) {
      handleOnDrag(...agrs);
    } else {
      handleOnClickPolicy();
    }
    setDragging(false);
  }

  const handleOnSelectPolicy = (e) => {
    e.stopPropagation();
    const tempPolicy = { ...policy };
    tempPolicy.selected = e.target.checked;
    tempPolicy.selectedAmount = e.target.checked ? Number(policy.present_value) : 0;
    
    dispatch(
      updatePolicy({ ...tempPolicy })
    );
  }

  useEffect(() => {
    if (payout && !payout.loading && payout.data && payout.data.policies) {
      setPolicy(payout.data.policies[policyId]);
    }
  }, [payout, policyId]);

  return policy ? <>
    <div className="policy">
      <Draggable
        axis="x"
        handle=".front"
        defaultPosition={{ x: 0, y: 0 }}
        position={position}
        // grid={[25, 25]}
        scale={1}
        onDrag={handleOnDrag}
        onStop={handleOnStop}
        cancel=".policy-selected-state"
      >
        <div className="front">
          <span>
            <span className="policy-case-number">#{policy?.caseNumber ? policy.caseNumber : ''}</span>
            <span className="policy-provider-logo" style={{ backgroundImage: `url(${policy?.policyProvider?.logo ? policy?.policyProvider?.logo : ''})` }}></span>
            <span className="policy-provider-name text-ellipsis">{policy?.policyProvider?.name ? policy.policyProvider.name : ''}</span>
          </span>
          <span className="handle">
            <span>total value of policy</span>
            <span className="policy-present-value">${policy?.present_value ? Number(policy.present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</span>
            <span className="policy-payment-streams">Pst:<span className="ps-count">{policy?.payment_streams ? String(Object.keys(policy.payment_streams).length).padStart(2, '0') : 0}</span></span>
          </span>
          <span>
            <span className="checkbox policy-selected-state">
              <input
                id={`input-select-policy-${policy.id}`}
                type="checkbox"
                name={`policy-${policy.id}`}
                onChange={handleOnSelectPolicy}
                checked={!!policy.selected}
              />
            </span>
          </span>
        </div>
      </Draggable>
      <div className="back">
        <span>
          <span>
            <span>${policy?.selectedAmount ? Number(policy.selectedAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</span>
            <span>of ${policy?.present_value ? Number(policy.present_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</span>
            <span className="policy-progress-bar">
              <span className="track" style={{ width: `${Number(policy.selectedAmount) > 0 ? (Number(policy.selectedAmount) / Number(policy.present_value)) * 100 : 0}%` }}></span>
            </span>
          </span>
        </span>
        <button className="btn btn-details" onClick={() => setPolicyOverview(policy)}><LiaInfoCircleSolid size={20} style={{ marginRight: 5 }} /> Details</button>
      </div>
    </div>
  </> : null;
}

export default PolicyCard;